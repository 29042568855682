import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Calendar } from "primereact/calendar";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "./styles/steps.css";
import Rating from "@mui/material/Rating";
import moovIcone from "../images/moovIcone.png";
import orangeIcone from "../images/orangeIcone.png";
import mtnIcone from "../images/mtnIcone.jpg";
import waveIcone from "../images/waveIcone.png";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";

function MoovIcone() {
  return <img className="w-[10%] mr-3" src={moovIcone} alt="Moov Icone" />;
}

function OrangeIcone() {
  return <img className="w-[10%] mr-3" src={orangeIcone} alt="Orange Icone" />;
}

function MtnIcone() {
  return <img className="w-[10%] mr-3" src={mtnIcone} alt="MTN Icone" />;
}

function WaveIcone() {
  return <img className="w-[16%] " src={waveIcone} alt="Wave Icone" />;
}

export { MoovIcone, OrangeIcone, MtnIcone, WaveIcone };

function Steps() {
  const times = [
    { label: "8 h 50", value: "8 h 50" },
    { label: "12 h 05", value: "12 h 05" },
    { label: "16 h 35", value: "16 h 35" },
    { label: "10 h 00", value: "10 h 00" },
  ];

  const steps = ["Thématique", "Date/Heure", "Paiement"];

  const categories = [
    {
      id: "moov",
      label: "Moov",
      icon: <MoovIcone />,
      inputs: [
        {
          id: "numeroMoov",
          label: "Numero Moov",
          type: "number",
          placeholder: "0103050634",
          required: true,
          className: 'overflow-hidden'
        },
      ],
    },
    {
      id: "orange",
      label: "Orange",
      icon: <OrangeIcone />,
      inputs: [
        {
          id: "tokenClient",
          label: "Token du Client",
          type: "text",
          placeholder: "ksndfoij23k904mlef893k",
          disabled: true,
        },
        {
          id: "code",
          label: "Code",
          type: "number",
          placeholder: "3563",
          className: 'overflow-hidden'
        },
        {
          id: "numeroOrange",
          label: "Numero Orange",
          placeholder: "0103050634",
          type: "number",
          className: 'overflow-hidden',
          required: true,
        },
      ],
    },
    {
      id: "wave",
      label: "Wave",
      icon: <WaveIcone />,
      inputs: [
        {
          id: "nomPrenoms",
          label: "Nom et Prenoms",
          placeholder: "Welfa Idrisse",
          type: "text",
          required: true,
        },
        {
          id: "numeroWave",
          label: "Numero wave",
          placeholder: "0103050634",
          type: "number",
          className: 'overflow-hidden',
          required: true,
        },
        {
          id: "montant",
          label: "Montant",
          placeholder: "50000 f",
          required: true,
          type: "number",
          className: 'overflow-hidden'
        },
      ],
    },
    {
      id: "mtn",
      label: "MTN",
      icon: <MtnIcone />,
      inputs: [
        {
          id: "numeroMTN",
          label: "Numero MTN",
          type: "number",
          placeholder: "0103050634",
          className: 'overflow-hidden'
        },
      ],
    },
  ];

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [addInfo, setAddInfo] = useState(false);

  const [value, setValue] = useState(0);
  const [selectedNetwork, setSelectedNetwork] = useState(false);
  const [network, setNetwork] = useState("");
  const [raisonCoaching, setRaisonCoaching] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [date, setDate] = useState(null);
  const [tableauRDV, setTableauRDV] = useState([
    {
      date: date,
      heure: selectedTime,
      // date: "",
      // heure: "",
    },
  ]);
  const [selectedOption, setSelectedOption] = useState("");
  const [inputValues, setInputValues] = useState({});
  const [formValid, setFormValid] = useState(false);

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [id]: value,
    }));

    const isFormValid = validateForm(selectedOption);
    setFormValid(isFormValid);
  };

  
  const validateForm = (selectedCategory) => {
    const category = categories.find((cat) => cat.id === selectedCategory);
    if (!category) {
      return false; // La catégorie sélectionnée n'a pas été trouvée
    }
    for (const input of category.inputs) {
      if (input.required && (!inputValues[input.id] || inputValues[input.id] === "")) {
        return false; // Un champ requis est manquant ou vide
      }
    }
    return true; // Tous les champs requis sont valides
  };

  
  const handleOptionChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    setInputValues({})
  };

  console.log(inputValues);

  const handleTimeClick = (time) => {
    setSelectedTime(time);
  };

 

  console.log(network);

  const handleChangeDescriptionValue = (e) => {
    setRaisonCoaching(e.target.value);
    setAddInfo(true);
  };

  console.log(raisonCoaching);
  console.log(date);
  console.log(tableauRDV[0].date, tableauRDV[0].heure);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setSelectedNetwork(false);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setTableauRDV([
      {
        date: null,
        heure: null,
      },
    ]);

    setDate(null);
    setSelectedTime(null);
    setInputValues({})
    setSelectedOption('')
  };
  const handleReset = () => {
    setSelectedNetwork(false)
    setRaisonCoaching(null)
    setSelectedTime(null)
    setDate(null);
    setTableauRDV([{
      date: null,
      heure: '',
    }])
    setSelectedOption('')
    setInputValues({})
    setFormValid(false)
    setActiveStep(0);

  };

  useEffect(() => {
    if (date && selectedTime) {
      setTableauRDV([
        {
          date: date,
          heure: selectedTime,
        },
      ]);
    }
  }, [date, selectedTime]);

  return (
    <div className="flex flex-col pt-12 w-[90%] mx-auto  h-full bg-[#fff] ">
      <div className="flex flex-col justify-center items-center flex-wrap border-white h-full w-full">
        <div className="mb-6 w-full font-medium">
          {activeStep === 0 ? (
            <div className="font-medium">Raison du RDV</div>
          ) : activeStep === 1 ? (
            <div className="text-medium"> Prise de Rendez vous </div>
          ) : (
            <h1 className="font-medium"> Paiement</h1>
          )}
        </div>

        {/* les steps  */}

        <Stepper
          className="flex md:flex-row sm:flex-col flex-wrap w-full py-2 bg-[#d6f0e3]"
          activeStep={activeStep}
        >
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        {activeStep === steps.length ? (
          <div className="flex flex-col md:flex-row justify-around mt-12 items-center w-full md:w-[70%] ">
            <div className="text-center mb-4 md:mb-0">
            Toutes les étapes sont terminées - vous avez terminé(e)
            </div>
            <div className="py-1 px-4 mx-2 text-black bg-[#0ac37f]  rounded-xl" >
              <button className="text-black"  onClick={handleReset}>Valider</button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col w-full">
            <div className="flex flex-col justify-center items-center mt-6  w-full">
              {activeStep === 0 ? (
                <div className="flex flex-col flex-wrap mt-12 h-64 mx-auto w-[80%] ">
                  <label>Description</label>
                  <textarea
                    name="description"
                    value={raisonCoaching}
                    onChange={handleChangeDescriptionValue}
                    className=" flex flex-row overflow-hidden flex-wrap h-36 pl-4 mt-2 pb-20 rounded-2xl outline-none border-[1px] "
                    placeholder="Pourquoi souhaitez vous un coaching..."
                  />
                </div>
              ) : activeStep === 1 ? (
                <>
                  {" "}
                  <div className="flex flex-col justify-between items-center w-[100%] gap-4">
                    <div className="flex flex-col justify-center w-full h-[90%]">
                      <div className="flex md:flex-row flex-col justify-between ">
                        <h2 className="font-bold text-center md:text-left">
                          Date
                        </h2>
                        <Rating
                          className="flex justify-center md:justify-right"
                          // name="simple-controlled"
                          name="half-rating"
                          defaultValue={2.5}
                          precision={0.5}
                          value={value}
                          onChange={(event, newValue) => {
                            setValue(newValue);
                          }}
                        />
                      </div>

                      <div className="flex flex-col justify-center">
                        <div className="card flex justify-content-center">
                          <Calendar
                            value={date}
                            onChange={(e) => setDate(e.value)}
                            locale="en"
                            inline
                          />
                        </div>
                        <div className="text-center mt-3">
                          Selection une date de rendez-vous en touchant une date
                          sur du calendrier
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col mt-6 w-[100%]">
                      <h2 className="font-bold text-center md:text-center">
                        Heure
                      </h2>
                      <div className="flex flex-row flex-wrap mt-4 justify-center gap-4 w-full">
                        {times.map((time, index) => (
                          <button
                            key={index}
                            className={`border-2 border-gray-200 hover:border-green-500 py-2 px-4 ${
                              selectedTime === time.value
                                ? "focus:border-green-500"
                                : ""
                            }`}
                            onClick={() => handleTimeClick(time.value)}
                          >
                            {time.label}
                          </button>
                        ))}
                      </div>
                      <div className="text-center mt-3">
                        Selectionner l'heure qui vous convient en touchant le
                        bouton d heure concerné
                      </div>
                    </div>
                    {tableauRDV &&
                      tableauRDV.length > 0 &&
                      tableauRDV.map(
                        (rdv, index) =>
                          rdv.heure !== null &&
                          rdv.date !== null && (
                            <div
                              key={index}
                              className="flex flex-row font-medium italic flex-wrap"
                            >
                              <h2>
                                Votre RDV est pris pour {rdv.heure} le{" "}
                                {rdv.date.toLocaleDateString()}
                              </h2>
                            </div>
                          )
                      )}
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <span className="font-bold mt-6">
                      Choisissez votre methode de paiement
                    </span>
                  </div>
                        
                        {/* formulaire de paiement */}
                 
                 
                  <div className="flex flex-row mt-4 w-full  mb-6 md:w-[50%] mx-auto ">
                    
                    <div>
                      {categories.map((category) => (
                        <Accordion
                          key={category.id}
                          expanded={selectedOption === category.id}
                        >
                          <AccordionSummary
                            aria-controls={`${category.id}-content`}
                            id={`${category.id}-header`}
                            className=""
                          >
                            <div className="flex flex-row items-center justify-between w-full">
                              <div>
                                <input
                                  type="radio"
                                  id={category.id}
                                  name="mobile-money"
                                  value={category.id}
                                  checked={selectedOption === category.id}
                                  onChange={handleOptionChange}
                                />
                                <label
                                  htmlFor={category.id}
                                  className="ml-2 cursor-pointer"
                                >
                                  {category.label}
                                </label>
                              </div>
                              {category.icon}
                            </div>
                          </AccordionSummary>
                          <div className="flex flex-col mb-12 justify-center items-center">
                            {category.inputs.map((input) => (
                              <div
                                key={input.id}
                                className="form-control w-full max-w-xs"
                              >
                                <label className="label">
                                  <span className="label-text font-medium">
                                    {input.label}
                                  </span>
                                </label>
                                <input
                                  type={input.type}
                                  id={input.id}
                                  placeholder={input.placeholder}
                                  required={input.required}
                                  disabled={input.disabled}
                                  value={inputValues[input.id] || ""}
                                  onChange={handleInputChange}
                                  className="w-full border-[1px] px-2 py-4 rounded-2xl"
                                />
                              </div>
                            ))}
                          </div>
                        </Accordion>
                      ))}
                    </div>
                  </div>
                </>
              )}

              {selectedNetwork ? (
                <div className="">
                  <div className="form-control w-full max-w-xs">
                    <label className="label font-medium">
                      <span className="label-text">Nom et Prenom</span>
                    </label>
                    <input
                      type="text"
                      placeholder="votre Nom et Prenoms"
                      className="input input-bordered w-full max-w-xs rounded-2xl"
                    />
                  </div>
                  <div className="form-control w-full max-w-xs">
                    <label className="label font-medium">
                      <span className="label-text">Contact</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Contact"
                      className="input input-bordered w-full max-w-xs rounded-2xl"
                    />
                  </div>
                  <div className="form-control w-full max-w-xs">
                    <label className="label ">
                      <span className="label-text">Montant</span>
                    </label>
                    <input
                      type="text"
                      placeholder="50000"
                      className="input input-bordered w-full max-w-xs rounded-2xl"
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        )}

        {addInfo ? (
          <div className="flex mt-24 mb-6 flex-row justify-center items-center">
            {activeStep === 0 && raisonCoaching !== "" ? (
              <>
                <button
                  className="py-1 px-4 mx-2 bg-[#0ac37f] rounded-xl"
                  onClick={handleNext}
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Suivant"}
                </button>
              </>
            ) : activeStep === 1 &&
              tableauRDV.length > 0 &&
              tableauRDV[0].heure !== null &&
              tableauRDV[0].date !== null ? (
              <>
                <button
                  className="py-1 px-4 mx-2 bg-[#c5cdca] rounded-xl"
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  Back
                </button>

                <button
                  className="py-1 px-4 mx-2 bg-[#0ac37f] rounded-xl"
                  onClick={handleNext}
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Suivant"}
                </button>
              </>
            ) : activeStep === 2 &&
              tableauRDV.length > 0 &&
              tableauRDV[0].heure !== null &&
              tableauRDV[0].date !== null &&
              Object.keys(inputValues).length > 0 && !Object.values(inputValues).every(value => value === '')

              ? (
              <>
                <button
                  className="py-1 px-4 mx-2 bg-[#c5cdca] rounded-xl"
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  Back
                </button>

                <button
                  className= {` py-1 px-4 mx-2 ${!formValid ? 'gray-bg' : 'bg-[#0ac37f]'} rounded-xl`}
                  onClick={handleNext}
                  disabled={!formValid}
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Suivant"}
                </button>
              </>
            ) : (
              <button
                className="py-1 px-4 mx-2 bg-[#c5cdca] rounded-xl"
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
              >
                Back
              </button>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Steps;