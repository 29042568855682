import React, { useState } from "react";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import icone1 from "../images/icone1.png";
import icone2 from "../images/icone2.png";
import icone3 from "../images/icone3.png";
import { useNavigate } from "react-router-dom";

const steps = [
  {
    img: icone1,
    label: "Trouvez des Coachs",
    description: `For each ad campaign that you create, you can control how much
              you're willing to spend on clicks and conversions, which networks
              and geographical locations you want your ads to show on, and more.`,
  },
  {
    img: icone2,
    label: "Des thèmes pratiques",
    description:
      "An ad group contains one or more ads which target a shared set of keywords.",
  },
  {
    img: icone3,
    label: "Satisfaction garantie",
    description: `Try out different ad text to see what brings in the most customers,
              and learn how to enhance your ads using features like ad extensions.
              If you run into any problems with your ads, find out how to tell if
              they're running and how to resolve approval issues.`,
  },
];

function Test() {


  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = 3; // Replace with the actual number of steps

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    setActiveStep(maxSteps - 1);
  };


  const navigate = useNavigate();
  // const [activeStep, setActiveStep] = React.useState(0);
  // const maxSteps = steps.length;

  const handleSubmit = () => {
    navigate("/step");
  };
  return (
    <div
      className="flex flex-col justify-center  w-full h-screen
    "
    >
      <Box
        className="flex flex-col justify-center items-center  h-full bg-[#05c780] w-full"
    
      >
        <Box sx={{ height: 255, maxWidth: 400, width: "100%", p: 2 }}>
          {steps && (
            <img
              className="backgroundImage-none"
              src={steps[activeStep].img}
              alt=""
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
            />
          )}
        </Box>
        <Typography
          sx={{
            color: "#fff",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          {steps[activeStep].label}
        </Typography>

        <Box
          sx={{
            height: 155,
            maxWidth: 400,
            width: "100%",
            p: 4,
            color: "#c1fffd",
          }}
        >
          {steps[activeStep].description}
        </Box>
        {/* <MobileStepper
          className="flex flex-col flex-wrap"
          variant="dots"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          sx={{
            bgcolor: "transparent",
            "& .MuiMobileStepper-dot": {
              backgroundColor: "#018b58",
              margin: "0 5px",
              mt: 6,
              cursor: "pointer",
            },
            "& .MuiMobileStepper-dotActive": {
              backgroundColor: "white",
            },
            "& .MuiButton-root": {
              color: "black",
            },
          }}
          onClick={(event) => {
            if (event.target.classList.contains("MuiMobileStepper-dot")) {
              const dotIndex = Array.from(
                event.target.parentNode.children
              ).indexOf(event.target);
              setActiveStep(dotIndex);
            }
          }}
        />

        <div className="flex w-full justify-center ">
          {activeStep === 2 ? (
            <Button
              sx={{
                display: "flex",
                width: "70%",
                maxWidth:'50%',
                backgroundColor: "#7a1600",
                "&:hover": {
                  backgroundColor: "#a82300", // Définir la couleur de fond au survol
                },
                marginLeft: "10px",
                marginRight: "10px",
                borderRadius: "10px",
                color: "#fff",
                mt: 3,
              }}
            >
              <span onClick={handleSubmit}>Passer</span>
            </Button>
          ) : null}
        </div> */}
         <MobileStepper
        className="flex flex-col flex-wrap"
        variant="dots"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        sx={{
          bgcolor: "transparent",
          "& .MuiMobileStepper-dot": {
            backgroundColor: "#018b58",
            margin: "0 5px",
            mt: 6,
            // cursor: "pointer",
          },
          "& .MuiMobileStepper-dotActive": {
            backgroundColor: "white",
          },
          "& .MuiButton-root": {
            color: "black",
          },
        }}
      />

      <div className="flex w-full justify-center">
        {activeStep !== maxSteps - 1 && (
          <Button
            sx={{
              display: "flex",
              width: "70%",
              maxWidth: "50%",
              backgroundColor: "#7a1600",
              "&:hover": {
                backgroundColor: "#a82300",
              },
              marginLeft: "10px",
              marginRight: "10px",
              borderRadius: "10px",
              color: "#fff",
              mt: 3,
            }}
            onClick={handleNext}
          >
            Suivant
          </Button>
        )}

        {activeStep === maxSteps - 1 && (
          <Button
            sx={{
              display: "flex",
              width: "70%",
              maxWidth: "50%",
              backgroundColor: "#7a1600",
              "&:hover": {
                backgroundColor: "#a82300",
              },
              marginLeft: "10px",
              marginRight: "10px",
              borderRadius: "10px",
              color: "#fff",
              mt: 3,
            }}
            onClick={handleSubmit}
          >
            Passer
          </Button>
        )}
      </div>
      </Box>
    </div>
  );
}

export default Test;
