import React from 'react'
import Test from '../layout/Test'
import Steps from '../layout/Steps'
import { Route, Routes } from 'react-router-dom'
import LandingPage from '../LandingPage'

function Router() {
  return (
    
      <Routes>
          <Route path="/test" element={<Test />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/step" element={<Steps />} />
      </Routes>
 
  )
}

export default Router
