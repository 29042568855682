import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./layout/styles/navbar.css";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import Grp85 from "./images/Group 85.png";
import Iphone12Pro from "./images/iPhone 12 Pro.png";
import Iphone12Pro2 from "./images/iPhone 12 Pro (2).png";
import Iphone12Pro3 from "./images/iPhone 12 Pro (3).png";
import Iphone12Pro4 from "./images/iPhone 12 Pro (1).png";
import imgPlayStore from "./images/en_badge_web_generic 1.png";
import imgAppStore from "./images/App-Store-Logo_0 1.png";
import imgProfil from "./images/Design sans titre (27) 5.png";
import cercleRose2 from "./images/Group 91.svg";
import quote from "./images/charm_quote.png";
import Grp86 from "./images/Group 86.png";
import Grp91 from "./images/Group 91.png";
import Grp93 from "./images/Group 93.png";

const LandingPage = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  const accordionStyle = {
    backgroundColor: isExpanded ? "red" : "transparent",
  };

  return (
    <>
      <div className="flex flex-col border-red-400  w-full h-full">
        <div className="flex flex-col bg-[#02C77F]  pb-10 border-rose-600 w-full h-screen ">
          <div className="flex flex-col w-full h-[20vh]">
            <div className="flex flex-row w-full justify-end">
              <img src={Grp85} className="w-[150px]" alt="icone cercle" />
            </div>

            <div>
              <div className="navbar  mt-[-7rem] bg-none text-white">
                <div className="navbar-start">
                  <Link to="/" className=" normal-case ml-4">
                    <h1 className="text-[25px] font-bold">
                      <span className="text-[#C72602]">SKAN</span>COACHING
                    </h1>
                  </Link>
                </div>
                <div className="navbar-center ml-2 hidden md:flex lg:flex ">
                  <ul className="flex gap-6 menu-horizontal mr-8 px-1">
                    <li>
                      <a href="#fonctionnaliteAncre" className="lienNavig">
                        Fonctionnalité
                      </a>
                    </li>
                    <li>
                      <a className="lienNavig" href="#temoignagne">
                        Témoignages
                      </a>
                    </li>
                    <li>
                      <a className="lienNavig" href="#FaqAncre">
                        FAQ
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="navbar-end  hidden md:flex md:gap-x-4 md:flex-nowrap lg:flex ">
                  <Link
                    className="lienNavig"
                    to="https://admin.skanpay.io/#/login"
                  >
                    Se connecter
                  </Link>

                  <button className=" bg-[#7A1600]  py-2 px-2 rounded-2xl">
                    <Link
                      to="https://admin.skanpay.io/#/inscription"
                      target="_blank"
                      className="flex flex-row flex-nowrap items-center text-[12px] md:text-md text-white  "
                    >
                      Ouvrir un compte
                    </Link>
                  </button>
                </div>
                <div className="md:hidden w-full text-end mr-4">
                  <div className="drawer  drawer-end">
                    <input
                      id="my-drawer"
                      type="checkbox"
                      className="drawer-toggle"
                    />
                    <div className="drawer-content">
                      <label htmlFor="my-drawer" className="btn drawer-button">
                        <MenuRoundedIcon />
                      </label>
                    </div>
                    <div className="drawer-side">
                      <label
                        htmlFor="my-drawer"
                        className="drawer-overlay"
                      ></label>
                      <ul className="flex flex-col gap-y-4 justify-start items-start pt-[3rem] p-4 w-80 h-full bg-base-200 text-base-content">
                        <li>
                          <NavLink className="lienNavig" to="/apropos">
                            Fontionnaliés
                          </NavLink>
                        </li>
                        <li>
                          <NavLink className="lienNavig" to="/tarifs">
                            Témoignages
                          </NavLink>
                        </li>
                        <li>
                          <NavLink className="lienNavig" to="/developpeurs">
                            FAQ
                          </NavLink>
                        </li>
                        <li>
                          <NavLink className="lienNavig" to="/register">
                            Se connecter
                          </NavLink>
                        </li>
                        <li>
                          <NavLink className="lienNavig" to="/register">
                            S'inscrire
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center  w-full h-full ">
            <div className="flex flex-row justify-center gap-4  h-full  md:w-[80%] ">
              <div className="md:w-[45%] w-[50%] flex items-center justify-center">
                <img
                  src={Iphone12Pro}
                  className="md:w-[200px] w-[300px] h-[65vh] md:h-auto"
                  alt="image_iphone12"
                />
              </div>

              <div className="md:w-[50%] w-[40%]  flex flex-col gap-4 justify-center items-center">
                <div className="flex flex-col gap-4">
                  <h1 className="md:text-[35px] text-[20px] text-white font-bold leading-8">
                    Entrez dans le nouveau monde du coaching
                  </h1>
                  <p className="text-white md:text-[18px] text-[15px]  text-start ">
                    La plateforme #1 de coaching en ligne en Côte d’Ivoire
                  </p>
                </div>
                <div className="flex md:gap-4 gap-1 mr-4 md:mr-0">
                  <img
                    src={imgAppStore}
                    className="md:w-[20%] w-[60%]"
                    alt="imgAppStore"
                  />
                  <img
                    src={imgPlayStore}
                    className="md:w-[20%] w-[60%]"
                    alt="imgPlaystore"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full py-6 border-yellow-500 ">
          <div
            className="flex md:flex-row flex-col justify-center md:w-[90%] w-full md:mx-auto mx-0  pb-12 gap-4 md:h-[90vh] h-[full]"
            style={{
              backgroundImage: `url('${Grp86}')`,
              backgroundSize: "29%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right 20% bottom 15%",
            }}
          >
            <div className="flex flex-col  md:pt-24 pt-8 md:ml-32 justify-center md:w-[40%] w-[90%] mx-auto">
              <h2
                id="fonctionnaliteAncre"
                className="text-[30px] font-bold leading-9"
              >
                Trouver votre coachs quel que soit le domaine
              </h2>
              <p className="mt-4">
                Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et
                massa mi. Aliquam in hendrerit urna.Lorem ipsum dolor sit amet
                consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit
                urna.
              </p>
              <div className="md:flex hidden  flex-col  mt-16 justify-around">
                <img src={Grp93} className="w-[10%] ml-24" alt="groupe86" />
              </div>
            </div>

            <div
              style={{
                backgroundImage: `url('${cercleRose2}')`,
                backgroundSize: "30%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right 24% top 1%",
              }}
              className="flex flex-col  md:items-center mt-6 md:mt-0 justify-center md:w-[40%] w-full "
            >
              <img
                src={Iphone12Pro2}
                className="w-[220px] md:ml-2"
                alt="iphone12 Pro 2"
              />
            </div>
          </div>

          <div
            className="flex md:flex-row flex-col mt-12 justify-center gap-4 md:w-[90%] w-full md:mx-auto mx-0   pb-12  md:h-[90vh] h-[full]"
            style={{
              backgroundImage: `url('${cercleRose2}')`,
              backgroundSize: "20%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "left 38% bottom -1%",
            }}
          >
            <div className="flex flex-col  md:items-center md:ml-44 ml-0 mt-6 md:mt-0 justify-center md:w-[30%] w-[95%] mx-auto ">
              <img
                src={Iphone12Pro3}
                className="w-[220px] md:ml-2 ml-16"
                alt="iphone12 Pro 2"
              />
            </div>
            <div className="flex flex-col  pt-10 justify-center md:mr-20 mr-6 md:w-[40%] w-[90%] mx-auto">
              <div className="flex flex-col w-[90%] mb-6">
                <h2 className="text-[30px] font-bold leading-9">
                  Prise de rendez-vous simplifié
                </h2>
                <p className="mt-4">
                  Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et
                  massa mi. Aliquam in hendrerit urna.Lorem ipsum dolor sit amet
                  consectetur adipiscing elit Ut et massa mi. Aliquam in
                  hendrerit urna.
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-[90%] justify-center items-center mt-8 mx-auto ">
            <div className="flex flex-col justify-center items-center">
              <h2 id="temoignagne" className="text-[25px] font-bold">
                Témoignages
              </h2>
              <p className="text-center">
                Des utilisateurs satisfaits vous partage leur expérience.
              </p>
            </div>
            <div className="grid md:grid-cols-3 sm:grid-cols-2 mt-20 grid-cols-1 text-center gap-4 flex-col w-full">
              <div className="card w-[90%] ml-4 md:ml-0 bg-base-100 border-[1px] shadow-xl">
                <div className="flex justify-end">
                  <img src={quote} className="mt-4 mr-4" alt="grille" />
                </div>
                <div className="card-body items-start text-start">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et
                    massa mi. Aliquam in hendrerit urna.Lorem ipsum dolor sit
                    amet consectetur
                  </p>
                  <div className="flex flex-row justify-start gap-x-3 w-full">
                    <img src={imgProfil} alt="profil_photo" />
                    <div>
                      <h2>Corinne Konan</h2>
                      <p>Lorem ipsum</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card w-[90%] ml-4 md:ml-0 bg-base-100 border-[1px] shadow-xl">
                <div className="flex justify-end">
                  <img src={quote} className="mt-4 mr-4" alt="grille" />
                </div>
                <div className="card-body items-start text-start">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et
                    massa mi. Aliquam in hendrerit urna.Lorem ipsum dolor sit
                    amet consectetur
                  </p>
                  <div className="flex flex-row justify-start gap-x-3 w-full">
                    <img src={imgProfil} alt="profil_photo" />
                    <div>
                      <h2>Corinne Konan</h2>
                      <p>Lorem ipsum</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card w-[90%] ml-4 md:ml-0 bg-base-100 border-[1px] shadow-xl">
                <div className="flex justify-end">
                  <img src={quote} className="mt-4 mr-4" alt="grille" />
                </div>
                <div className="card-body items-start text-start">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et
                    massa mi. Aliquam in hendrerit urna.Lorem ipsum dolor sit
                    amet consectetur
                  </p>
                  <div className="flex flex-row justify-start gap-x-3 w-full">
                    <img src={imgProfil} alt="profil_photo" />
                    <div>
                      <h2>Corinne Konan</h2>
                      <p>Lorem ipsum</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center  w-[90%] mx-auto mt-10">
            <div className="w-full text-center ">
              <h2 id="FaqAncre" className="text-[25px] font-bold">
                FAQ
              </h2>
            </div>

            <div className="md:w-[70%] w-[95%] mx-auto  py-5 px-4">
              <div className="collapse my-4 pl-10 rounded-md border-[1px] collapse-plus bg-white">
                <input type="radio" name="my-accordion-3" />
                <div className="collapse-title text-xl font-medium">
                  Quels sont les moyens de paiement acceptés ?
                </div>
                <div className="collapse-content">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et
                    massa mi. Aliquam in hendrerit urna.Lorem ipsum dolor sit
                    amet consectetur
                  </p>
                </div>
              </div>
              <div className="collapse pl-10 my-4 rounded-md border-[1px] collapse-plus bg-white">
                <input type="radio" name="my-accordion-3" />
                <div className="collapse-title text-xl font-medium">
                  Quels sont les moyens de paiement acceptés ?
                </div>
                <div className="collapse-content">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et
                    massa mi. Aliquam in hendrerit urna.Lorem ipsum dolor sit
                    amet consectetur
                  </p>
                </div>
              </div>
              <div className="collapse  pl-10 my-4 rounded-md border-[1px] collapse-plus bg-white">
                <input type="radio" name="my-accordion-3" />
                <div className="collapse-title text-xl font-medium">
                  Quels sont les moyens de paiement acceptés ?
                </div>
                <div className="collapse-content">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et
                    massa mi. Aliquam in hendrerit urna.Lorem ipsum dolor sit
                    amet consectetur
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            className="flex md:flex-row flex-col mt-12 justify-center gap-4 md:w-[90%] w-full md:mx-auto mx-0   pb-12  md:h-[90vh] h-[full]"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.1)), url('${cercleRose2}')`,
              backgroundSize: "30%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "left 40% bottom 50%",
            }}
          >
            <div className="flex flex-row md:items-center md:ml-44 ml-0 mt-6 md:mt-0 justify-center md:w-[30%] w-full  mx-auto ">
              <img
                src={Iphone12Pro4}
                className="w-[220px] md:ml-2 ml-0"
                alt="iphone12 Pro 2"
              />
            </div>
            <div className="flex flex-col pt-10 justify-center md:mr-20 mr-6 md:w-[40%] w-[90%] mx-auto">
              <div className="flex flex-col items-center md:items-start gap-4">
                <h1 className="md:text-[35px] text-[20px] text-black font-bold leading-8">
                  Téléchargez l’application
                </h1>
                <p className="text-black md:text-[18px] text-[15px] text-center  md:text-start ">
                  Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et
                  massa mi. Aliquamjujykrk
                </p>
              </div>
              <div className="flex flex-row justify-center md:justify-start">
                <Link to="https://podcasts.apple.com/us/podcast/master-coaching-with-ajit/id1534427616">
                  <img
                    src={imgAppStore}
                    className="w-[100px]"
                    alt="imgAppStore"
                  />
                </Link>
                <Link to="https://play.google.com/store/apps/details?id=org.jw.jwlanguage&hl=fr&gl=US">
                  <img
                    src={imgPlayStore}
                    className="w-[100px]"
                    alt="imgPlaystore"
                  />
                </Link>
              </div>
            </div>
          </div>

          <div
            className="w-full hidden md:block  h-[10vh] mt-[-5rem] mb-[6rem]"
            style={{
              backgroundImage: `url('${Grp86}')`,
              backgroundSize: "5%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "left 55% top 15%",
            }}
          ></div>
        </div>

        <footer className="footer footer-center p-10 bg-[#474747] rounded">
          <div className="flex flex-col gap-4">
            <Link to="/" className="text-[#B6B6B6]">
              {" "}
              <h2 className="text-[25px] ">
                <span className="font-bold text-white ">SKAN</span>COACHING
              </h2>
            </Link>
            <p className="text-[#B6B6B6]">
              Prise de rendez-vous et séance de coaching simplifiés
            </p>
          </div>
          <div>
            <div className="grid grid-flow-col text-white gap-4">
            <Link>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                  className="fill-current"
                >
                  <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path>
                </svg>
              </Link>
              <Link>
                <InstagramIcon fontSize="large"/>
              </Link>
              <Link>
              <LinkedInIcon fontSize="large" />
              </Link>
              
            </div>
          </div>
          <div className="flex md:flex-row flex-col justify-between items-center w-full">
            <div className="flex flex-col mb-4 md:mb-0 items-center gap-2">
              <h4 className="text-white ">
                <Link className="mx-4" to="#">
                  Conditions d’utilisation{" "}
                </Link>{" "}
                <span className=" text-[25px] mb-6">.</span>{" "}
                <Link to="#" className="mx-4">
                  Conditions d’utilisation{" "}
                </Link>{" "}
              </h4>
              <p className="text-[#C4C4C4] text-start">
                Copyright © 2023 - SKAN TECHNOLOGY
              </p>
            </div>

            <div className="flex md:flex-row flex-col gap-y-4  gap-x-4 justify-center items-center">
              <div className="flex flex-row justify-center items-center gap-x-1 leading-7">
                <PhoneEnabledIcon className="text-[#C4C4C4]" />
                <div>
                  <h2 className="text-white">Contact du support</h2>
                  <Link to="#" className="link link-hover text-white">
                    +225 05 66 15 76 59
                  </Link>
                </div>
              </div>
              <div className="flex flex-row justify-center items-center gap-x-1 leading-7">
                <EmailOutlinedIcon className="text-[#C4C4C4]" />
                <div>
                  <h2 className="text-white">Email du support</h2>
                  <Link to="#" className="link link-hover text-white">
                    info@skanci.com
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default LandingPage;
